import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ProgressiveImage from 'react-progressive-image'
import { random } from 'lodash'
import VisibilitySensor from 'react-visibility-sensor'

import { Layout, Block } from '../components'
import { container, padding, colours, type } from '../styles/global'
import { media } from '../styles/utils'
import { parseACF } from '../utils'

import redCircle from '../assets/icons/red-circle.svg'
import char01 from '../assets/characters/black/illustration-01.png'
import char02 from '../assets/characters/black/illustration-02.png'
import char03 from '../assets/characters/black/illustration-03.png'
import char04 from '../assets/characters/black/illustration-04.png'

const About = (props) => {
    const [isHover, setHover] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [pause, pauseinterval] = useState(false)
    const [background, setBackground] = useState('#eae6e2')
    const [theme, setTheme] = useState('normal')

    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    // Render Sections

    const toggleOnScroll = (visible, section) => {
        switch (visible) {
            case section == 'Approach':
                setBackground('#eae6e2')
                setTheme('normal')
                break
            case section == 'Capabilties' ||
                section == 'Collaborators' ||
                section == 'Where we operate':
                setBackground('#f91e0e')
                setTheme('red')
                break
            default:
                setBackground('#eae6e2')
                setTheme('normal')
                break
        }
    }

    const renderSections = (props) => {
        if (!data.list_blocks) return

        const blocks = data.list_blocks.map((item, i) => {
            return (
                <>
                    <VisibilitySensor
                        key={i}
                        onChange={(visible) =>
                            toggleOnScroll(visible, item.heading)
                        }
                        partialVisibility={true}
                        minTopPercent={1}
                        offset={{
                            bottom: 300,
                        }}
                    >
                        <SectionItem key={i}>
                            {item.heading && (
                                <Heading
                                    theme={theme}
                                    dangerouslySetInnerHTML={{
                                        __html: item.heading,
                                    }}
                                />
                            )}

                            <Block
                                layout={'about-list'}
                                list={item.list}
                                includeLink={item.use_links}
                                theme={theme}
                                display
                            />
                        </SectionItem>
                    </VisibilitySensor>
                </>
            )
        })

        return <SectionWrapper>{blocks}</SectionWrapper>
    }

    return (
        <Layout
            meta={data && data.seo}
            backgroundColor={background}
            theme={theme}
            useBlackCharacters={true}
            menuMessage={'Purveyors of Talent for Modern Production'}
            hideFooter={true}
            stopResize={true}
        >
            <Container>
                {data.intro && (
                    <Intro
                        dangerouslySetInnerHTML={{
                            __html: data.intro,
                        }}
                    />
                )}

                {data.approach && (
                    <VisibilitySensor
                        onChange={(visible) =>
                            toggleOnScroll(visible, 'Approach')
                        }
                        partialVisibility={true}
                        offset={{
                            top: 1200,
                        }}
                    >
                        <Approach>
                            <Heading>Approach</Heading>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: data.approach,
                                }}
                            />
                        </Approach>
                    </VisibilitySensor>
                )}

                {renderSections()}
            </Container>
        </Layout>
    )
}

// Shared
const Wrapper = styled.div``
const Text = styled.div``
const Heading = styled.h2``
const Item = styled(motion.div)``
const Icon = styled(motion.img)``
const Character = styled(motion.img)``
const Label = styled.p``

// Container

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
`

// Intro

const Intro = styled.h1`
    ${type.heading1}
    padding: 180px 20px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;

    ${media.tablet`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		padding: 130px 0;
	`}
`

// Approach

const Approach = styled.div`
    display: flex;
    justify-content: space-between;

    ${media.tablet`
		flex-direction: column;
	`}

    ${Heading} {
        ${type.heading2}

        ${media.tablet`
			padding-bottom: 130px;
		`}
    }

    ${Text} {
        flex-basis: 75%;
        padding-right: 80px;
        box-sizing: border-box;

        ${media.tablet`
			padding-right: 0;
		`}
    }
`

// Sections

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: -60px;
    margin-right: -60px;
    padding: 60px;
    box-sizing: border-box;
`

const SectionItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 120px 0 250px;

    ${media.tablet`
		flex-direction: column;
	`}

    ${Heading} {
        ${type.heading2}
        position: sticky;
        top: 150px;
        height: fit-content;

        ${media.tablet`
			padding-bottom: 130px;
			position: relative;
			top: auto;
		`}
    }
`

// Query

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default About
